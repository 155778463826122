import { useState } from 'react';
import Color from '../../utils/colors';
import Button from '../button/button';
import DetailButton from '../detail-button/detail-button';
import './main-search.scss';

const NPC_DETAILS = [
  '😇 Name',
  '📃 Description',
  '📖 Backstory',
  '😱 Secrets',
  '😢 Flaws and weaknesses',
  '😍 Want/ needs/ motives',
  '👄 How they speak',
  '👾 Personality trait',
  '💰 Loot',
  '👐 Mannerism',
  '❤️ Relationships',
  '💪 Physical description',
  '👥 Races',
  '📯 Class',
  '🤖 Occupation',
];

const MainSearch = () => {
  const [recipe, setRecipe] = useState(null);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});

  const handleDetail = (detail) => {
    setSelectedDetails((prevSelectedDetails) =>
      selectedDetails[detail] === true
        ? { ...prevSelectedDetails, [detail]: false }
        : { ...prevSelectedDetails, [detail]: true }
    );
    console.log(recipe, selectedDetails);
  };

  const handleSelectAll = () => {
    setSelectAll((prevSelectAll) => !prevSelectAll);
  };

  const handleSubmit = () => {
    setLoading(true);
    setClicked(true);
    const detailsArray = selectAll
      ? [...NPC_DETAILS]
      : Object.keys(selectedDetails).filter(
          (detail) => selectedDetails[detail] === true
        );
    console.log(recipe, detailsArray);

    fetch('/api/character-gen', {
      method: 'post',
      body: JSON.stringify({ excluded_headers: [], num_completions: 1 }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setLoading(false);
          setItems(result);
        },
        (error) => {
          setLoading(false);
          console.log(error);
        }
      );
    console.log('items:', items);
  };

  return (
    <div className="main-search-page">
      <div
        style={{
          background: Color.Background,
          width: '100%',
          marginTop: '5rem',
        }}
      >
        <h1 style={{ margin: '1rem' }}>What do you want to generate?</h1>
        <select
          style={{
            borderRadius: '15rem',
            height: '3rem',
            width: '100%',
            maxWidth: '30rem',
            padding: '0rem 2rem',
            border: `solid 0.1rem ${Color.InputBorder}`,
            backgroundColor: Color.InputBackground,
            color: Color.Text,
            fontSize: '1rem',
            margin: '2rem',
          }}
          onChange={(e) => setRecipe(e.currentTarget.value)}
        >
          <option value="null" hidden>
            Choose an option...
          </option>
          <option value="Character Details">Character Details</option>
          <option value="Non-Playable Character">Non-Playable Character</option>
        </select>
      </div>

      {recipe && (
        <div>
          <div
            style={{
              display: 'flex',
              marginLeft: '2rem',
              maxWidth: '100rem',
            }}
          >
            <Button
              className="select-all"
              text="Select All"
              onClick={handleSelectAll}
              backgroundColor={
                selectAll ? Color.SelectAllHover : Color.DetailButton
              }
              border={`solid 0.1rem ${Color.Text}`}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'left',
              flexWrap: 'wrap',
              margin: '0rem 2rem 2rem 2rem',
              maxWidth: '100rem',
            }}
          >
            {NPC_DETAILS.map((detail) => (
              <DetailButton
                key={detail}
                text={detail}
                isSelected={selectAll}
                onClick={handleDetail}
              />
            ))}
          </div>
        </div>
      )}

      <div style={{ background: Color.Background, width: '100%' }}>
        <Button
          text={clicked && !loading ? 'Generate Another!' : 'Generate!'}
          onClick={handleSubmit}
          {...(recipe === null
            ? {
                disabled: true,
                backgroundColor: Color.PrimaryButtonDisabled,
                className: 'button-disabled',
              }
            : { backgroundColor: Color.PrimaryButton, className: 'button' })}
        />
      </div>

      {loading ? (
        <div>
          <div
            className="loader"
            style={{
              border: `solid 1.6rem ${Color.InputBackground}`,
              borderTop: `solid 1.6rem ${Color.InputBorder}`,
              borderRadius: '50%',
              width: '3rem',
              height: '3rem',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '5rem',
            }}
          />
        </div>
      ) : (
        <div style={{ alignContent: 'left' }}>
          <ul>
            {items.map((item, key) => (
              <li key={key}>{item.content}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MainSearch;
