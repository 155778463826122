import PropTypes, { string } from 'prop-types';
// import { useState } from 'react';
import Color from '../../utils/colors';
import './button.scss';

const Button = (props) => {
  const { text, onClick, backgroundColor, disabled, className, border } = props;
  return (
    <button
      className={className}
      onClick={onClick}
      type="button"
      disabled={disabled}
      style={{
        height: '3rem',
        width: '18rem',
        border: border,
        fontSize: '1.5rem',
        borderRadius: '0.8rem',
        color: Color.Text,
        backgroundColor: backgroundColor || Color.PrimaryButton,
        transition: '0.2s',
        margin: '2rem 1rem',
      }}
    >
      {text}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  disabled: PropTypes.bool,
  className: string,
  border: string,
};

Button.defaultProps = {
  onClick: null,
  backgroundColor: Color.PrimaryButton,
  disabled: false,
  className: 'button',
  border: 'none',
};

export default Button;
