const Color = {
  PrimaryButton: '#50C878',
  PrimaryButtonHover: '#2d8f4e',
  PrimaryButtonDisabled: '#8AC79E',
  InputBorder: '#F9C700',
  InputBackground: 'rgba(255, 204, 0, .22)',
  SelectAllBorder: '#7B897C',
  SelectAllHover: '#48593F',
  DetailButton: '#191C21',
  DetailButtonHover: '#354868',
  Background: '#282c34',
  Text: '#ffffff',
};

export default Color;
