import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Color from '../../utils/colors';
import './detail-button.scss';

const DetailButton = (props) => {
  const { text, isSelected, onClick } = props;
  const [selected, setSelected] = useState(true);
  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);
  const handleClick = (e) => {
    setSelected((prevSelected) => !prevSelected);
    onClick(e.currentTarget.name);
  };
  return (
    <button
      className="detail-button"
      name={text}
      onClick={handleClick}
      type="button"
      style={{
        width: 'auto',
        fontSize: '1rem',
        borderRadius: '0.8rem',
        color: Color.Text,
        backgroundColor: selected
          ? Color.DetailButtonHover
          : Color.DetailButton,
        transition: '0.2s',
        padding: '.8rem',
        margin: '1rem 1rem',
      }}
    >
      {text}
    </button>
  );
};

DetailButton.propTypes = {
  text: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

DetailButton.defaultProps = {
  isSelected: false,
  onClick: null,
};

export default DetailButton;
