import './App.css';
import { Switch, Route } from 'react-router-dom';
import MainSearch from './components/main-search/main-search';

function App() {
  return (
    <Switch>
      <Route path="/" component={MainSearch} />
    </Switch>
  );
}

export default App;
